<!--@Time : 2021/11/16 10:57-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col cols="12" md="12" lg="12">
        <v-data-table
            v-if="isShow"
            :headers="headers_admin"
            :items="desserts"
            :loading="loading"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :server-items-length="totalDesserts"
            class="elevation-0"
        >
          <template v-slot:item.action="{ item }">
            <v-icon
                color="primary"
                small
                class="mr-2"
                @click="editItem(item)"
            >mdi-pencil</v-icon
            >
            <v-icon color="error" small @click="deleteItem(item)"
            >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
        <v-data-table
            v-else
            :headers="headers"
            :items="desserts"
            :loading="loading"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :server-items-length="totalDesserts"
            class="elevation-0"
        >
        </v-data-table>
        <v-btn v-if="isShow" color="primary" outlined small @click="addItem">Insert an Item</v-btn>
			</v-col>
		</v-row>

		<v-dialog v-model="dialog" max-width="600px" @click:outside="clearData">
			<v-card>
				<v-card-title v-if="itemCategory">New Item</v-card-title>
				<v-card-title v-else>Edit Item</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="6">
							<v-text-field
								v-model="item.violation_or_cheating"
								label="Violation or Cheating"
							>
							</v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field
								v-model="item.penalty_points"
								label="Penalty Points"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea
								v-model="item.infraction_description"
								label="Infraction Description"
							></v-textarea>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="clearData"
						>Cancel</v-btn
					>
					<v-btn
						v-if="itemCategory"
						color="primary"
						text
						small
						@click="postContest"
						>Save</v-btn
					>
					<v-btn
						v-else
						color="primary"
						text
						small
						@click="changeContest"
						>Update</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogDelete" max-width="600px">
			<v-card>
				<v-card-title>Confirm delete?</v-card-title>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="clearData"
						>No</v-btn
					>
					<v-btn color="primary" text small @click="deleteContest"
						>Yes</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Header from "@/components/Header";
import {
	addContest,
	delContest,
	listContest,
	updateContestPatch,
} from "@/api/solar/contest";
import { Message } from "element-ui";
import moment from "moment";
export default {
	name: "Penalty",
	components: { Header },
	data() {
		return {
			isShow:
				this.$store.getters.groups === 1 || this.$store.getters.groups === 2,
			headers_admin: [
				{ text: "#", value: "no" },
				{ text: "Date", value: "date" },
				{ text: "Violation or Cheating", value: "violation_or_cheating" },
				{ text: "Infraction Description", value: "infraction_description" },
				{ text: "Penalty Points", value: "penalty_points" },
				{ text: "Action", value: "action" },
			],
      headers: [
				{ text: "#", value: "no" },
				{ text: "Date", value: "date" },
				{ text: "Violation or Cheating", value: "violation_or_cheating" },
				{ text: "Infraction Description", value: "infraction_description" },
				{ text: "Penalty Points", value: "penalty_points" },
			],
			desserts: [],
			loading: false,

      page: 1,
      itemsPerPage: 10,
      totalDesserts: 0,

			itemCategory: false,

			item: {
				violation_or_cheating: null,
				penalty_points: null,
				infraction_description: null,
			},

			dialog: false,
			dialogDelete: false,

			penaltyData: null,
		};
	},
  created() {
    if (this.$store.getters.team){
      this.getDataFromApi()
    }
  },
  watch: {
    page: {
      handler() {
        this.getDataFromApi();
      },
    },
    itemsPerPage: {
      handler() {
        this.getDataFromApi();
      },
    },
		"$store.getters.team": {
      handler() {
        this.getDataFromApi()
			},
		},
	},
	methods: {
		clearData() {
			this.dialog = false;
			this.dialogDelete = false;
			this.date = null;
			this.penaltyData = null;
			this.item.infraction_description = null;
			this.item.penalty_points = null;
			this.item.violation_or_cheating = null;
		},

		// 打开添加页面
		addItem() {
      if (this.$store.getters.team === null) {
        Message({
          showClose: true,
          message: "No team is selected",
          type: "warning",
        });
        return
      }
      this.clearData();
			this.itemCategory = true;
			this.dialog = true;
		},
		// 打开更新页面
		editItem(item) {
			this.clearData();
			this.itemCategory = false;
			this.dialog = true;
			this.penaltyData = item;
			this.item.infraction_description = item.infraction_description;
			this.item.penalty_points = item.penalty_points;
			this.item.violation_or_cheating = item.violation_or_cheating;
		},

		// 打开删除确认页面
		deleteItem(item) {
			this.dialogDelete = true;
			this.penaltyData = item;
		},

		// 上传一个新数据
		postContest() {
			this.dialog = false;
			if (this.$store.getters.team === null) {
				this.desserts = [];
				Message({
          showClose: true,
					message: "No team is selected",
					type: "warning",
				});
				return;
			}
			addContest({
				team: this.$store.getters.team.id,
				type: "penalty",
				task: "penalty",
				score: this.item.penalty_points,
				data: JSON.stringify(this.item),
			}).then(() => {
				Message({
          showClose: true,
					message: "Upload Successful",
					type: "success",
				});
				this.getDataFromApi();
			});
		},

		// 更新数据
		changeContest() {
			this.dialog = false;
			console.log(this.penaltyData);
			updateContestPatch(this.penaltyData.id, {
				score: parseInt(this.item.penalty_points),
				data: JSON.stringify(this.item),
			}).then(() => {
				Message({
          showClose: true,
					message: "Update Successful",
					type: "success",
				});
				this.getDataFromApi();
			});
		},

		// 删除一个数据
		deleteContest() {
			this.dialogDelete = false;
			delContest(this.penaltyData.id).then(() => {
				this.desserts.splice(this.penaltyData.no - 1, 1);
				this.totalDesserts -= 1;
				this.desserts.forEach((item, index) => {
					item.no = index + 1;
				});
				Message({
          showClose: true,
					message: "Delete Successful",
					type: "success",
				});
			});
		},

		getDataFromApi() {
      if (this.$store.getters.team === null) {
				this.desserts = [];
				return;
			}
			this.loading = true;
			listContest({
				team: this.$store.getters.team.id,
				type: "penalty",
        page: this.page, //当前页
				pagesize: this.pageSize(this.itemsPerPage), //每页大小
			}).then((res) => {
				this.desserts = [];
				this.totalDesserts = res["count"];
				res["results"].forEach((item, index) => {
					const data = JSON.parse(item["data"]);
					this.desserts.push({
						no: index + 1,
						id: item["id"],
						date: moment(item["update_time"]).format("YYYY-MM-DD HH:mm:ss"),
						infraction_description: data.infraction_description,
						penalty_points: data.penalty_points,
						violation_or_cheating: data.violation_or_cheating,
					});
				});
				this.loading = false;
			});
		},

    // 每页数量，当页面选ALL时，pagesize=-1, 后端没有对pagesize=-1处理，这里通过一个函数将其修改为pagesize=1000
		pageSize(pagesize) {
			if (pagesize === -1) {
				return 1000;
			} else return pagesize;
		}
	}
}
</script>

<style scoped>
</style>